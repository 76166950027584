<template>
  <div class="content page-box">
    <h2 class="title">合同详情</h2>
    <div class="nav-wrapper">
      <p class="subTitle">
        <span
          :class="['button', showInfo === 'contract' ? 'active' : '']"
          @click="checkNavList('contract')"
        >合同信息</span>
        <span v-if="contractInfo.bizFlag === '1' && displayBusiness" :class="['button', showInfo === 'businessinfo' ? 'active' : '']" @click="checkNavList('businessinfo')">
          业务信息
        </span>
        <span
          v-if="contractInfo.contractAgreementVos.length"
          :class="['button', showInfo === 'agreement' ? 'active' : '']"
          @click="checkNavList('agreement')"
        >合同变更记录({{contractInfo.contractAgreementVos.length}})</span>
        <span
          :class="['button', showInfo === 'payList' ? 'active' : '']"
          @click="checkNavList('payList')"
        >付款记录</span>
      </p>
      <!-- 版本 -->
      <div
        class="select-box"
        v-if="isShowContractVersion"
      >
        <span class="version-span">合同版本</span>
        <el-select
          v-model="version"
          placeholder="请选择"
          @change="selectVersionFn"
        >
          <el-option
            v-for="item in versionOptions"
            :key="item.id"
            :label="item.modifyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 合同信息 -->
    <section class="content-box" v-if="showInfo === 'contract'">
      <!-- 基本信息 -->
      <div class="detail-card">
        <header class="card-header">
          <!-- <h4 class="second-title">基本信息<span :class="['status', handelStatusBg(hasStatus.status), handelNameColor(hasStatus.status)]"><i :class="handelConStatus(hasStatus.status)"></i>{{ hasStatus.status | conStatusNameFilter }}</span></h4> -->
          <TitleBar
            title="基本信息"
            :innerBtnBox="true"
            :hasStatus="hasStatus"
          >
            <div
              slot="btnBox"
              v-if="hasStatus.status !== 'draft'"
            >
              <button
                class="btn btn-primary"
                @click="goApproveDetail"
              >
                审批详情
              </button>
            </div>
          </TitleBar>
        </header>
        <section class="card-content">
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">合同类型:</label>
                <p class="text">
                  {{ contractInfo.contractTypeValue }}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">合同名称:</label>
                <p class="text">{{ contractInfo.contractName }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">合同编号:</label>
                <p class="text">
                  {{ contractInfo.contractNo }}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">经办人:</label>
                <p class="text">
                  {{ contractInfo.operatorName }}
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">经办日期:</label>
                <p class="text">{{ contractInfo.contractDateStr }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">关联项目:</label>
                <p
                  class="text"
                  v-if="contractInfo.projectGoalFlag === 'Y'"
                >
                  {{ contractInfo.projectName | addSerial }}
                </p>
                <p
                  class="text"
                  v-else
                >非项目目的</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">是否需要付款:</label>
                <p class="text">
                  {{ contractInfo.needPay == "N" ? "不需要" : "需要" }}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">是否供方合同:</label>
                <p class="text">
                  {{ contractInfo.supplyStoreFlag == "1" ? "是" : "否" }}
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">形成方式:</label>
                <p class="text">{{ contractInfo.forTypeValue | empty }}</p>
              </div>
            </el-col>
            <el-col :span="6"   v-if="contractInfo.kgStage && contractInfo.contractType == 'COMMON'">
              <div class="item">
                <label class="label">所属阶段:</label>
                <p class="text">{{ contractInfo.kgStage | kgStageFormatter }}</p>
              </div>
            </el-col>
            <el-col :span="6"   v-if="contractInfo.isRelationFixPj == 1 && (contractInfo.contractType == 'COMMON')">
              <div class="item">
                <label class="label">具体合同类型:</label>
                <p class="text">{{ contractInfo.fixContractType | fixContractType }}</p>
              </div>
            </el-col>

          </el-row>
        </section>
      </div>
      <!-- 签约主体 -->
      <div class="detail-card">
        <header class="card-header">
          <h4 class="second-title">签约主体</h4>
        </header>
        <section class="card-content">
          <div class="qy-body-flex-wrapper">
            <!-- 模块一 -->
            <div class="qy-body-item pink">
              <h2 class="qy-body-item-title">我方签约主体</h2>
              <ul class="qy-body-item-detail">
                <li class="qy-body-item-detail-li">
                  <ul>
                    <li v-if="!(contractInfo.ourCompanyList && contractInfo.ourCompanyList.length)">-</li>
                    <li v-for="(item, index) in contractInfo.ourCompanyList" :key="index">{{item.name}}</li>
                  </ul>
                </li>
              </ul>
            </div>
            <!-- 模块二 -->
            <div class="qy-body-item pink">
              <h2 class="qy-body-item-title">对方签约主体</h2>
              <ul class="qy-body-item-detail">
                <li class="qy-body-item-detail-li">
                  <ul>
                    <li v-if="!(contractInfo.supplierCompanyList && contractInfo.supplierCompanyList.length)">-</li>
                    <li v-for="(item, index) in contractInfo.supplierCompanyList" :key="index">
                      {{item.name}}
                      <!-- <span v-show="item.type === 'T04'">供方</span> -->
                      <span v-show="item.type === 'T04'" v-supplier>{{item.supplierSourceName || '供方'}}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <!-- 框架类协议金额修改合同金额模块 -->
      <div class="detail-card" v-if="isShowContractVersion && isShowAmountChange">
        <header class="card-header">
          <h4 class="second-title">合同金额</h4>
        </header>
        <section class="card-content">
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">修改类型:</label>
                <p class="text">
                  {{ contractInfo.modifyMoneyTypeName | empty }}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">修改金额:</label>
                <p class="text">{{ contractInfo.modifyMoneyNum | millimeterFormat | empty }}</p>
              </div>
            </el-col>
          </el-row>
          <div class="qy-body-flex-wrapper">
            <!-- 模块一 -->
            <div class="qy-body-item pink">
              <h2 class="qy-body-item-title">变更后</h2>
              <ul class="qy-body-item-detail">
                <el-row style="margin-top:0">
                  <el-col :span="6" class="text-align-right">币种：</el-col>
                  <el-col :span="6">{{contractInfo.currency}}</el-col>
                  <el-col :span="6" class="text-align-right">合同金额(含税)(A)：</el-col>
                  <el-col :span="6">{{contractInfo.amount | millimeterFormat | empty }}</el-col>
                </el-row>
                <el-row>
                  <el-col :span="6" class="text-align-right">不计成本金额(B)：</el-col>
                  <el-col :span="6">{{contractInfo.costAmount | millimeterFormat | empty}}</el-col>
                  <el-col :span="6" class="text-align-right">有效签约金额(C=A-B)：</el-col>
                  <el-col :span="6">{{contractInfo.effContractAmount | millimeterFormat | empty}}</el-col>
                </el-row>
                <el-row>
                  <el-col :span="6" class="text-align-right">发票类型：</el-col>
                  <el-col :span="6">{{contractInfo.invoiceTypeValue}}</el-col>
                  <el-col :span="6" class="text-align-right">可抵扣税额：</el-col>
                  <el-col :span="6">{{contractInfo.deductibleTax | millimeterFormat | empty}}</el-col>
                </el-row>
                <el-row>
                  <el-col :span="6" class="text-align-right">可抵扣税率：</el-col>
                  <el-col :span="6">{{contractInfo.deductibleTaxRate  | formatRate}}</el-col>
                  <el-col :span="6" class="text-align-right">合同金额(不含抵扣税)：</el-col>
                  <el-col :span="6">{{contractInfo.exContractAmount | millimeterFormat | empty}}</el-col>
                </el-row>
              </ul>
            </div>
            <!-- 模块二 -->
            <div class="qy-body-item gray">
              <h2 class="qy-body-item-title">变更前</h2>
              <ul class="qy-body-item-detail">
                <el-row style="margin-top:0">
                  <el-col :span="6" class="text-align-right">币种：</el-col>
                  <el-col :span="6">{{modifyLastVo.currency}}</el-col>
                  <el-col :span="6" class="text-align-right">合同金额(含税)(A)：</el-col>
                  <el-col :span="6">{{modifyLastVo.amount | millimeterFormat | empty }}</el-col>
                </el-row>
                <el-row>
                  <el-col :span="6" class="text-align-right">不计成本金额(B)：</el-col>
                  <el-col :span="6">{{modifyLastVo.costAmount | millimeterFormat | empty}}</el-col>
                  <el-col :span="6" class="text-align-right">有效签约金额(C=A-B)：</el-col>
                  <el-col :span="6">{{modifyLastVo.effContractAmount | millimeterFormat | empty}}</el-col>
                </el-row>
                <el-row>
                  <el-col :span="6" class="text-align-right">发票类型：</el-col>
                  <el-col :span="6">{{modifyLastVo.invoiceTypeValue}}</el-col>
                  <el-col :span="6" class="text-align-right">可抵扣税额：</el-col>
                  <el-col :span="6">{{modifyLastVo.deductibleTax | millimeterFormat | empty}}</el-col>
                </el-row>
                <el-row>
                  <el-col :span="6" class="text-align-right">可抵扣税率：</el-col>
                  <el-col :span="6">{{modifyLastVo.deductibleTaxRate  | formatRate }}</el-col>
                  <el-col :span="6" class="text-align-right">合同金额(不含抵扣税)：</el-col>
                  <el-col :span="6">{{modifyLastVo.exContractAmount | millimeterFormat | empty}}</el-col>
                </el-row>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <!-- 招采信息 -->
      <div class="detail-card handle-down"  v-if="!isShowAmountChange">
        <header class="card-header">
          <h4 class="second-title">招采信息</h4>
        </header>
        <section class="card-content">
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
          >
            <el-col :span="24">
              <div class="item">
                <label class="label">招标/比价/直委:</label>
                <p class="text">
                  {{ contractInfo.orderMsg | empty }}
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
            v-if="orderFile.length !== 0"
          >
            <el-col :span="14">
              <div class="item">
                <label class="label label-fill">附件:</label>
                <div class="text">
                  <el-table
                    :show-header="false"
                    :data="orderFile"
                  >
                    <el-table-column
                      prop="fileName"
                      min-width="250"
                    >
                      <template slot-scope="scope">
                        <div>
                          <i class="el-icon-document"></i>
                          <a
                            @click="preview(scope.row, id)"
                            class="linkName"
                          >{{ scope.row.fileName }}</a>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="createDate"
                      min-width="70"
                    >
                      <template slot-scope="scope">
                        <div class="light">
                          <span>{{ scope.row.bizCreateDateStr }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      min-width="50"
                    >
                      <template slot-scope="scope">
                        <div class="orderDownload">
                          <span @click="download(scope.row)">下载</span>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 合同金额 -->
      <div class="detail-card"  v-if="!isShowAmountChange">
        <header class="card-header">
          <h4 class="second-title">合同金额</h4>
        </header>
        <section class="card-content">
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">币种:</label>
                <p class="text">
                  {{ contractInfo.currency | empty }}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">合同金额(含税)(A):</label>
                <p class="text">
                  {{ contractInfo.contractAmount | millimeterFormat }}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">不计成本金额(B):</label>
                <p class="text">
                  {{ contractInfo.costAmount | millimeterFormat | empty}}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">有效签约金额(C=A-B):</label>
                <p class="text">
                  {{ contractInfo.effContractAmount | millimeterFormat | empty}}
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="10"
            type="flex"
            align="normal"
          >
            <el-col :span="6">
              <div class="item">
                <label class="label">发票类型:</label>
                <p class="text">{{ contractInfo.invoiceTypeValue | empty }}</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">可抵扣税额:</label>
                <p class="text">
                  {{ contractInfo.deductibleTax | millimeterFormat | empty }}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">可抵扣税率:</label>
                <p class="text">
                  {{ contractInfo.deductibleTaxRate | formatRate }}
                </p>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item">
                <label class="label">合同金额(不含抵扣税):</label>
                <p class="text">
                  {{ contractInfo.exContractAmount | millimeterFormat | empty }}
                </p>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" v-if="contractInfo.aboveQuotaAmount || contractInfo.aboveQuotaScale">
            <el-col :span="6">
              <div class="item">
                <label class="label">可超额付款比例/金额:</label>
                <p
                  class="text"
                  v-if="contractInfo.aboveQuotaType == 'FIX'"
                >
                  {{ contractInfo.aboveQuotaAmount | millimeterFormat | empty }}
                </p>
                <p
                  class="text"
                  v-else
                >
                  {{ contractInfo.aboveQuotaScale | formatRate }}
                </p>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 费项分摊 -->
      <div
        class="detail-card"
        v-if="contractInfo.needPay === 'Y'"
      >
        <header class="card-header">
          <h4 class="second-title">费项分摊</h4>
        </header>
        <section class="card-content">
          <el-row>
            <el-table
              :data="contractInfo.costExpenseList"
              ref="costDate"
              style="width: 100%"
              border
              fit
              :header-row-style="{ height: '36px' }"
            >
              <el-table-column
                v-for="(item, index) in costColumn"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :align="item.align"
              ></el-table-column>
              <el-table-column
                align="right"
                label="分摊金额(含税)"
              >
                <template slot-scope="scope">
                  {{ scope.row.amount | millimeterFormat | empty }}
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </section>
      </div>
      <!-- 合同付款约定 -->
      <div
        class="detail-card"
        v-if="contractInfo.needPay === 'Y' &&  !isShowAmountChange"
      >
        <header class="card-header">
          <h4 class="second-title">合同付款约定</h4>
        </header>
        <section class="card-content">
          <el-row>
            <el-col :span="24">
              <div class="item">
                <label class="label">说明信息:</label>
                <p class="text">
                  {{ contractInfo.payAgreement | empty }}
                </p>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 合同说明 -->
      <div class="detail-card" v-if="!isShowAmountChange">
        <header class="card-header">
          <h4 class="second-title">合同说明</h4>
        </header>
        <section class="card-content">
          <el-row>
            <el-col :span="24">
              <div class="item">
                <label class="label">说明信息:</label>
                <p class="text">
                  {{ contractInfo.contractNote | empty }}
                </p>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 信息修改原因 -->
      <div class="detail-card" v-if="isShowContractVersion && isShowInfoChangeCause">
        <header class="card-header">
          <h4 class="second-title">信息修改原因</h4>
        </header>
        <section class="card-content">
          <el-row>
            <el-col :span="24">
              <div class="item">
                <label class="label">说明信息:</label>
                <p class="text">
                  {{ contractInfo.infoChangeCause | empty }}
                </p>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 框架协议修改说明 -->
      <div class="detail-card" v-if="isShowContractVersion && isShowAmountChange">
        <header class="card-header">
          <h4 class="second-title">框架协议修改说明</h4>
        </header>
        <section class="card-content">
          <el-row>
            <el-col :span="24">
              <div class="item">
                <label class="label">说明信息:</label>
                <p class="text">
                  {{ contractInfo.infoChangeCause | empty }}
                </p>
              </div>
            </el-col>
          </el-row>
        </section>
      </div>
      <!-- 附件 -->
      <div class="detail-card fileCard">
        <header class="card-header file-header">
          <h4 class="second-title">附件</h4>
          <button
            class="btn btn-lucency"
            @click="downloadAll"
            v-if="fileData.length !== 0"
          >
            下载全部附件
          </button>
        </header>
        <section class="card-content">
          <el-row>
            <el-table
              :data="fileData"
              ref="fileData"
              style="width: 100%"
              border
              fit
              :header-row-style="{ height: '36px' }"
            >
              <el-table-column
                label="附件"
                min-width="454"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <i class="el-icon-document"></i>
                  <a
                    @click="preview(scope.row, id)"
                    class="linkName"
                  >{{ scope.row.fileName }}</a>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(item, index) in fileColumn"
                :key="index"
                :label="item.label"
                :align="item.align"
                :min-width="item.minWidth"
              >
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="top" v-if="scope.row.fileNote && item.prop === 'fileNote' && scope.row.fileNote.length >= 66">
                  <p>{{ scope.row[item.prop] }}</p>
                  <div slot="reference" class="name-wrapper">
                    {{ scope.row[item.prop].slice(0, 66) }}{{ '...' }}
                  </div>
                </el-popover>
                <span v-else>{{ scope.row[item.prop] }}</span>
              </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="left"
                min-width="56"
              >
                <template slot-scope="scope">
                  <div class="fileDownload">
                    <span @click="download(scope.row)"> 下载 </span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </section>
      </div>
    </section>
    <!-- 业务信息 -->
    <section class="content-box businessinfo" v-show="showInfo === 'businessinfo'">
      <div v-if="showIframe">
        <iframe name="businessinfoIframe" class="business-info-wrapper" :src="iframeUrl" title=""></iframe>
      </div>
    </section>
    <!-- 合同变更记录 -->
    <section class="content-box" v-if="showInfo === 'agreement'">
      <div class="detail-card no-bottom-border">
        <header class="card-header">
          <h4 class="second-title">合同变更</h4>
        </header>
        <section class="card-content">
          <el-row>
            <el-table
              :data="contractInfo.contractAgreementVos"
              ref="agreementList"
              style="width: 100%"
              border
              fit
              :header-row-style="{ height: '36px' }"
            >
              <el-table-column
                type="index"
                width="50"
                label="序号"
              >
              </el-table-column>
              <el-table-column
                v-for="(item, index) in agreementColumn"
                :key="index"
                :label="item.label"
                :prop="item.prop"
                :align="item.align"
                :min-width="item.minWidth"
              >
                <template slot-scope="scope">
                  <a v-if="item.prop === 'agreementName'" @click.prevent="goAgreeDetail(scope.row)">{{scope.row[item.prop]}}</a>
                  <span v-else>{{scope.row[item.prop]}}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="变更金额"
                prop="amount"
                min-width="120"
                align="right"
              >
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.amount | millimeterFormat | empty }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="合同状态"
                prop="auditStatus"
                min-width="72"
              >
                <template slot-scope="scope">
                  <div>
                    <span
                      class="statusIcon"
                      :class="handelApproveStatus(scope.row.auditStatus)"
                    ></span>
                    <a @click="jumpViewUrl(scope.row)" class="statusName">{{
                            scope.row.auditStatus
                          }}</a>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                min-width="60"
                align="left"
              >
                <template slot-scope="scope">
                  <div>
                    <span
                      class="linkBtn"
                      @click="goAgreeDetail(scope.row)"
                    >详情</span>
                  </div>
                </template>
              </el-table-column>
              <template slot="empty">
                <img
                  class="data-pic"
                  src="../assets/img/empty.png"
                  alt=""
                />
                <p class="data-text">暂无合同变更记录</p>
              </template>
            </el-table>
          </el-row>
        </section>
      </div>
    </section>
    <!-- 付款记录 -->
    <section class="content-box" v-if="showInfo === 'payList'">
      <div
        class="empty no-data-wrapper"
        v-if="!contractInfo.contractPayInfoVos.length"
      >
        <img
          src="../assets/img/empty.png"
          alt=""
        />
        <p>暂无付款记录</p>
      </div>
      <div v-else>
        <!-- 付款记录 -->
        <div class="detail-card">
          <header class="card-header">
            <h4 class="second-title">
              付款情况
              <el-popover
                popper-class="popoverBackB"
                placement="top-end"
                width="455"
                trigger="hover"
                v-model="visible"
              >
                <ul class="popover-text">
                  <li>说明： 累计实付=SUM (实付金额)</li>
                  <li>累计付款=SUM (计划状态为“审批中”或“已审批”的“实际申请金额”之和)</li>
                </ul>
                <i
                  class="el-icon-warning-outline suffix"
                  slot="reference"
                ></i>
              </el-popover>
            </h4>
            <div class="explain">
              <div class="item">
                <span class="label">合同金额：</span>
                <span class="text">{{ contractInfo.contractAllPayInfo.contractAmount | millimeterFormat }}</span>
              </div>
              <div class="item">
                <span class="label">累计实付：</span>
                <span class="text">{{ contractInfo.contractAllPayInfo.accumRealPay | millimeterFormat | empty }}</span>
              </div>
              <div class="item">
                <span class="label">累计付款：</span>
                <span class="text">{{ contractInfo.contractAllPayInfo.accumPay | millimeterFormat | empty }}</span>
              </div>
              <div class="item">
                <span class="label">累计实付比例：</span>
                <span class="text">{{ contractInfo.contractAllPayInfo.proportion | empty }}</span>
              </div>
            </div>
          </header>
        </div>
        <div class="detail-card no-bottom-border">
          <header class="card-header">
            <h4 class="second-title">
              付款记录
            </h4>
          </header>
          <section class="card-content">
            <el-row>
              <el-table
                :data="contractInfo.contractPayInfoVos"
                ref="payRecord"
                style="width: 100%"
                border
                fit
                :header-row-style="{ height: '36px' }"
              >
                <el-table-column
                  v-for="(item, index) in payRecordColumn"
                  :key="index"
                  :label="item.label"
                  :prop="item.prop"
                  :align="item.align"
                  :min-width="item.minWidth"
                >
                </el-table-column>
                <el-table-column
                  label="付款提交日期"
                  prop="paySubmitDateStr"
                  width="132"
                ></el-table-column>
                <el-table-column
                  label="交易金额"
                  prop="transAmount"
                  width="132"
                  align="right"
                >
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.payAmount | millimeterFormat }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="币种"
                  prop="currency"
                  width="90"
                >
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.currency | empty }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="资金流向"
                  prop="moneyFlow"
                  width="90"
                >
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.moneyFlow }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="审批状态"
                  prop="auditStatus"
                  width="120"
                >
                  <template slot-scope="scope">
                    <div>
                      <span
                        class="statusIcon"
                        :class="handelApproveStatus(scope.row.auditStatus)"
                      ></span>
                      <span class="statusName">{{
                              scope.row.auditStatus
                            }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="支付状态"
                  prop="payStatus"
                  width="120"
                >
                  <template slot-scope="scope">
                    <div>
                      <span
                        class="statusIcon"
                        :class="handelPayStatus(scope.row.payStatus)"
                      ></span>
                      <span class="statusName">{{
                              scope.row.payStatus
                            }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="52"
                  align="left"
                >
                  <template slot-scope="scope">
                    <div><span
                        class="linkBtn"
                        @click="goPayDetail(scope.row)"
                      >详情</span></div>
                  </template>
                </el-table-column>
                <template slot="empty">
                  <img
                    class="data-pic"
                    src="../assets/img/empty.png"
                    alt=""
                  />
                  <p class="data-text">暂无付款记录</p>
                </template>
              </el-table>
            </el-row>
          </section>
        </div>
      </div>
    </section>
    <!-- 底部按钮栏 -->
    <footer class="footer" v-if="(isShowExidBtn || ['approved', 'stamped', 'archived'].includes(hasStatus.status)) && this.rescindFlag !== 'Y' ">
      <!-- 草稿，已撤回，被驳回 -->
      <div
        class="btnBox"
        v-if="isShowExidBtn && this.currentUser === this.operatorNo"
      >
      <template v-if="contractInfo.bizFlag !== '1'">
         <button
          class="btn btn-lucency"
          @click="deleteChange"
        >删除</button>
        <button
          class="btn btn-primary"
          @click="clickExid"
        >编辑</button>
      </template>
      </div>
      <div
        class="btnBox"
        v-else
      >
        <template v-if="contractInfo.bizFlag !== '1'">

        <AuthControl code="FC_SHCMS_HTXQ_FQFK">
          <button
            class="btn btn-primary"
            @click="openPayDialog"
            v-if="['approved', 'stamped', 'archived'].includes(hasStatus.status)&&
              contractInfo.needPay === 'Y' &&
              contractInfo.bizFlag !== '1' && (this.currentUser === this.operatorNo || paymentLimit === 'Y')
            "
          >
            发起付款
          </button>
        </AuthControl>
        <!-- <button
          class="btn btn-lucency"
          v-if="
            ['approved', 'stamped', 'archived'].includes(
              contractInfo.contractStatus
            )
          "
          v-auth="'FC_SHCMS_HTXQ_XGXX'"
          @click="goCreatChange()"
        >
          修改信息
        </button> -->
         <AuthControl code="FC_SHCMS_HTBG_XJ">
            <button
              class="btn btn-lucency"
              v-if="hasStatus.status === 'archived' && (this.currentUser === this.operatorNo || modificationLimit === 'Y')"
              @click="goCreatAgree()"
            >
              发起变更
            </button>
         </AuthControl>
         </template>
        <button
          v-if="assignmentLimit === 'Y'"
          class="btn btn-lucency"
          @click="showAssignedDialog"
        >
          合同指派
        </button>
      </div>
    </footer>

    <!-- 交易付款单 -->
    <el-dialog
      title="交易付款"
      :visible.sync="payFormVisible"
      class="payBox"
      width="960px"
    >
      <el-form
        :model="payformData"
        size="small"
        label-width="115px"
        :rules="payFormRules"
        ref="payForm"
      >
        <el-row :gutter="2">
          <el-col :span="6">
            <div class="item">
              <p class="text">{{ payConInfo.contractAmount | millimeterFormat }}</p>
              <label class="label">合同总金额</label>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <p class="text">{{ payConInfo.planAmount | millimeterFormat }}</p>
              <label class="label">已做计划金额</label>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <p class="text">{{ payConInfo.accumPay | millimeterFormat }}</p>
              <label class="label">已申请金额</label>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <p class="text">{{ payConInfo.accumRealPay | millimeterFormat }}</p>
              <label class="label">已支付金额</label>
            </div>
          </el-col>
        </el-row>
        <el-row
          :gutter="2"
          type="flex"
          align="normal"
        >
          <el-col :span="8">
            <el-form-item
              label="合同名称:"
              prop="contractName"
            >
              <el-input
                v-model="payConInfo.contractName"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="资金流向:"
              prop="moneyFlow"
            >
              <el-input
                v-model="payformData.moneyFlow"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="币种:"
              prop="currency"
            >
              <el-input
                v-model="payformData.currency"
                disabled
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          :gutter="2"
          type="flex"
          align="normal"
        >
          <el-col :span="8">
            <el-form-item
              label="交易金额:"
              prop="payAmount"
            >
              <el-input
                placeholder="请输入交易金额"
                class="amount-input"
                v-model="payformData.payAmount"
                @input="limitInput($event, 'payAmount')"
                @keydown.enter.native.prevent="handle"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="我方签约主体:"
              prop="ourCompanyId"
            >
              <el-select
                collapse-tags
                filterable
                v-model="payformData.ourCompanyId"
                style="width: 100%"
                ref="ourCompany"
              >
                <template v-for="(item, index) of ourCompanyList">
                  <el-option
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="对方签约主体:"
              prop="supplierCompanyId"
            >
              <el-select
                collapse-tags
                filterable
                v-model="payformData.supplierCompanyId"
                ref="supplierCompany"
                style="width: 100%"
              >
                <template v-for="(item, index) of supplierCompanyList">
                  <el-option
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          :gutter="2"
          type="flex"
          align="normal"
        >
          <el-col :span="24">
            <el-form-item
              label="说明:"
              prop="pay_note"
            >
              <el-input
                type="textarea"
                size="small"
                placeholder="请输入内容"
                :autosize="{ minRows: 2, maxRows: 6 }"
                maxlength="5000"
                show-word-limit
                v-model="payformData.payNote"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <button
          class="btn btn-lucency"
          @click="
            () => {
              this.payFormVisible = false;
            }
          "
        >
          取消
        </button>
        <button
          class="btn btn-primary"
          @click="initiatePay"
        >提交</button>
      </div>
    </el-dialog>
    <!-- 预览 -->
    <ImageViewer @closeViewer="closeViewer" v-if="showImageViewer" :urls="imageUrl"></ImageViewer>
    <ContractAssignedDialog :bizFlag="contractInfo.bizFlag" :currentUserName="currentUserName" :id="id" @closeAssignedDialog="closeAssignedDialog" :dialogTableVisible="dialogTableVisible" v-if="dialogTableVisible"></ContractAssignedDialog>
  </div>
</template>
<script>
import {fixContractType,kgStageFormatter} from '@/utils/Strmap'
import TitleBar from "@/components/common/TitleBar.vue"
import preview from './mixins/preview'
import ContractAssignedDialog from './contractAssigned.vue'
export default {
  name: "conDetial",
  components: {
    TitleBar,
    ContractAssignedDialog
  },
  mixins: [preview],
  filters:{fixContractType,kgStageFormatter},
  data() {
    return {
      showIframe: false,
      displayBusiness: false,
      dialogTableVisible: false,
      T01ids: [],
      T02ids: [],
      comsT01: [], // 我方主体
      comsT02: [], // 对方主体
      visible: false,
      version: '', // 版本
      versionOptions: [], //版本option
      id: "", // 合同ID
      currentUser: "", // 当前登录人
      currentUserName: "",
      operatorNo: "", // 合同经办人
      approvalDetailUrl: "", // 审批详情地址
      //合同详情页状态
      hasStatus: {
        show: true,
        status: ""
      },
      //showIcon: false, // tips按钮显示
      //showTips: false, // tips显示
      payTip: false, // 付款记录 tip显示i
      rescindFlag:"", // 是否是已解除合同
      // 合同信息
      contractInfo: {
        contractAgreementVos: [],
        contractModifyVos: [],
        supplyStoreFlag: '0'
      },
      showInfo: 'contract',
      //activeName: "first", //默认tab签显示第一个
      // 招采附件
      orderFile: [],
      // 表头列表
      costColumn: [
        {
          label: "责任人",
          prop: "operatorName"
        },
        {
          label: "费项",
          prop: "costItemsName"
        },
        {
          label: "预算分摊部门",
          prop: "costItemsDeptName"
        }
      ],
      // 附件列表
      fileData: [],
      // 附件表头
      fileColumn: [
        {
          label: "备注",
          prop: "fileNote",
          minWidth: 462
        },
        {
          label: "上传时间",
          prop: "bizCreateDateStr",
          minWidth: 180
        }
      ],
      // 补充协议列表
      agreementList: [],
      // 补充协议列表表头
      agreementColumn: [
        {
          label: "变更合同名称",
          prop: "agreementName",
          minWidth: 360
        },
        {
          label: "变更类型",
          prop: "type",
          minWidth: 90
        },
        {
          label: "变更合同编号",
          prop: "agreementNo",
          minWidth: 150
        },
        {
          label: "经办日期",
          prop: "modifyDateStr",
          minWidth: 120
        }
      ],
      // 付款记录
      payRecord: [],
      // 付款记录表头
      payRecordColumn: [
        {
          label: "交易名称",
          prop: "transName",
          minWidth: 450
        }
      ],
      // 发起付款弹窗
      payFormVisible: false,
      // 发起付款表单规则
      payFormRules: {
        payAmount: [{ required: true, message: "请输入交易金额" }],
        ourCompanyId: [{ required: true, message: "请选择我方签约主体" }],
        supplierCompanyId: [{ required: true, message: "请选择对方签约主体" }],
      },
      // 交易付款-合同信息展示
      payConInfo: {},
      // 交易付款数据
      payformData: {
        moneyFlow: "支出",
        payAmount: "",
        ourCompanyId: "",
        supplierCompanyId: "",
        payNote: ""
      },
      // 我方签约主体列表
      ourCompanyList: [],
      supplierCompanyList: [],
      // 全局loading
      loading: null,
      sourceSystem: '',
      sourceId: '',
      approveFlag:'',
      iframeUrl: '',
      assignmentLimit: '', // 指派权限
      modificationLimit: '', // 变更权限
      paymentLimit: '', // 付款权限
      oldModify: {} // 框架类协议金额修改-修改前数据
    }
  },
  methods: {
    jumpViewUrl(row) {
      if (row.sourceSystem && (row.sourceSystem.includes('COST') || row.sourceSystem.includes('BILLS') || row.sourceSystem.includes('NEW_BILLS') || this.sourceSystem.includes('SHTZ_BILLS'))){
        const query = {
          "sourceSystem": row.sourceSystem,
          "dataType": 'AGREEMENT',
          "sourceContractId": row.sourceId,
          'id': row.id,
          "routerPath": this.$route.name,
          "contractId": this.contractInfo.id
        }
        this.$router.push({
          path: '/bpmHistory',
          query
        })
      } else {
        window.open(row.viewUrl)
      }
    },
    closeAssignedDialog(val) {
      this.dialogTableVisible = val
    },
    showAssignedDialog() {
      this.dialogTableVisible = true
    },
    handle() {},
    // 获取变更信息
    getChangeInfo(id) {
      let params = { id }
      return this.$request.post("/contractModify/getModifyInfo", { params }).then((res) => {
        if(res.code === "200") {
          let data = res.data
          // console.log('版本信息', data)
          this.hasStatus.status = data.auditStatus // 合同状态
          this.operatorNo = data.operatorNo
          this.contractInfo.contractTypeValue = data.contractTypeValue
          this.contractInfo.contractName = data.contractName
          this.contractInfo.contractNo = data.contractNo
          this.contractInfo.operatorName = data.operatorName
          this.contractInfo.contractDateStr = data.contractDateStr
          this.contractInfo.forTypeValue = data.forTypeValue
          this.contractInfo.needPay = data.needPay
          this.contractInfo.projectGoalFlag = data.projectGoalFlag
          this.contractInfo.projectName = data.projectName
          this.contractInfo.ourCompanyList = data.ourCompanyList
          this.contractInfo.supplierCompanyList = data.supplierCompanyList
          this.contractInfo.orderMsg = data.orderMsg
          this.contractInfo.supplyStoreFlag = data.supplyStoreFlag
          this.sourceId = data.sourceId
          this.contractInfo.infoChangeCause = data.infoChangeCause
          // this.assignmentLimit = data.assignmentLimit
          // this.modificationLimit = data.modificationLimit
          // this.paymentLimit = data.paymentLimit
          this.displayBusiness = data.displayBusiness || false
          if (this.displayBusiness) {
            this.iframeUrl = data.businessUrl + '&CASTGC=' + this.$store.state.CASTGC
            this.showIframe = true
          } else {
            this.showInfo = 'contract'
          }
          // 拆分招采附件和合同附件
          let fileList = data.attachFileList;
          this.orderFile = fileList.filter(item => {
            return item.fileCategory == "RECRUIT";
          })
          this.fileData = fileList.filter(item => {
            return item.fileCategory == "CONTRACT";
          })
          this.approvalDetailUrl = data.bpminnerUrl;
          this.contractInfo.currency = data.currency
          this.contractInfo.contractAmount = data.amount
          this.contractInfo.amount = data.amount
          this.contractInfo.costAmount = data.costAmount
          this.contractInfo.effContractAmount = data.effContractAmount
          this.contractInfo.invoiceTypeValue = data.invoiceTypeName
          this.contractInfo.deductibleTax = data.deductibleTax
          this.contractInfo.deductibleTaxRate = data.deductibleTaxRate
          this.contractInfo.exContractAmount = data.exContractAmount
          this.contractInfo.aboveQuotaType = data.aboveQuotaType
          this.contractInfo.aboveQuotaAmount = data.aboveQuotaAmount
          this.contractInfo.aboveQuotaScale = data.aboveQuotaScale
          this.contractInfo.costExpenseList = data.costExpenseList
          this.contractInfo.payAgreement = data.payAgreement
          this.contractInfo.contractNote = data.contractNote
          this.contractInfo.modifyMoneyTypeName = data.modifyMoneyTypeName
          this.contractInfo.modifyMoneyNum = data.modifyMoneyNum + ''
          this.approveFlag = data.approveFlag
          this.oldModify = data.oldModify
          this.modifyLastVo = data.modifyLastVo

        }

        this.loading.close()
      }).catch((error) =>{
        // 关闭全局loading
        if(this.loading) {
          this.loading.close()
        }
        this.$message.error(error)
      })
    },
        // 删除变更合同
    deleteChange() {
      this.$confirm("是否立即删除此合同，删除后，将无法恢复", "删除文件", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        lockScroll: false
      })
        .then(() => {
          let params = { id: this.version };
          this.$request
            .post("/contractModify/deleteModify", { params })
            .then((res) => {
              if (res.code === "200" && res.data == "success") {
                this.$message({
                  type: "success",
                  message: "删除成功",
                  duration: 1000,
                })
                this.getContractInfo(this.id)
              }
            })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          })
        });
    },
    // 切换合同版本
    selectVersionFn(val) {
      this.showIframe = false
      this.loading = this.$loading({
        fullscreen: true,
        background: "rgba(255, 255, 255, 0.8)",
        text: '正在加载数据...'
      })
      this.getChangeInfo(val)
    },
    // 获取合同ID
    getUrl() {
      return new Promise(resolve => {
        let id = this.$route.params.id;
        this.id = id;
        resolve(id);
      });
    },
    // 获取当前登录人信息
    getCurrentUser() {
      return this.$request.post("/employee/getCurrUser").then(res => {
        if(res.code === "200") {
          this.currentUser = res.data.currUserId;
          this.currentUserName = res.data.currUserName
        }
      });
    },
    // 查询所有主体
    queryEnterpriseAll() {
      return this.$request.post('/enterprise/queryEnterpriseAll').then(res => {
        if (res.code === '200') {
          this.comsT02 = res.data.T02
          this.comsT01 = res.data.T01
          return new Promise((resolve, rej) => {
            resolve(res.data.T02)
          })
        }
      })
    },
    // 获取合同信息
    getContractInfo(id) {
      return this.$request
        .post("/contract/queryContractById", { params: { id } })
        .then(res => {
          // 关闭全局loading
          if(res.code === "200") {
            let data = res.data
            this.contractInfo = data
            this.contractInfo.contractAgreementVos = data.contractAgreementVos || []
            this.contractInfo.contractPayInfoVos = data.contractPayInfoVos || []
            this.operatorNo = data.operatorNo
            this.hasStatus.status = data.contractStatus // 合同状态
            this.rescindFlag = data.rescindFlag
            this.versionOptions = data.hisVersionList || []
            this.sourceSystem = data.sourceSystem
            this.sourceId = data.sourceId
            this.assignmentLimit = data.assignmentLimit
            this.modificationLimit = data.modificationLimit
            this.paymentLimit = data.paymentLimit
            this.displayBusiness = data.displayBusiness
            if (this.displayBusiness) {
              this.iframeUrl = data.businessUrl + '&CASTGC=' + this.$store.state.CASTGC
              this.showIframe = true
            }
            // 如果有版本信息，拿到最新版本信息，请求修改内容
            if (data.hisVersionList.length) {
              this.version = data.hisVersionList.filter(item => item.newVersion === '1')[0].id
              this.showIframe = false
              this.getChangeInfo(this.version)
            }else {
              // 没有版本信息，关闭loading
              this.loading.close()
            }
            // 拆分招采附件和合同附件
            let fileList = data.attachFileList;
            this.orderFile = fileList.filter(item => {
              return item.fileCategory == "RECRUIT";
            })

            this.fileData = fileList.filter(item => {
              return item.fileCategory == "CONTRACT";
            })

            this.approvalDetailUrl = data.bpminnerUrl;

            this.approveFlag = data.approveFlag
          }


        }).catch((error) =>{
          // 关闭全局loading
          if(this.loading) {
            this.loading.close()
          }
          this.$message.error(error)
        });
    },
    // 查看审批结果
    goApproveDetail() {
      if (this.approvalDetailUrl) {
        window.open(this.approvalDetailUrl)
        return
      }
      if (this.sourceSystem && (this.sourceSystem.includes('COST') || this.sourceSystem.includes('BILLS') || this.sourceSystem.includes('NEW_BILLS') || this.sourceSystem.includes('SHTZ_BILLS'))){
        const query = {
          "sourceSystem": this.sourceSystem,
          // "dataType": 'CONTRACT',
          "dataType": this.approveFlag,
          "sourceContractId": this.sourceId,
          "id": this.$route.params.id,
          "routerPath": this.$route.name,
          "contractId": this.contractInfo.id
        }
        this.$router.push({
          path: '/bpmHistory',
          query
        })
      } else {
        window.open(`/nobpm.html`)
      }
    },
    // 查看付款详情
    goPayDetail(row) {
      if(row.costUrl) {
        window.open(row.costUrl)
      }else {
        this.$message({
          type: 'error',
          message: '未获取到详情链接'
        })
      }

    },
    // 补充协议-跳转补充协议详情
    goAgreeDetail(row) {
      if (this.currentUser !== row.operatorNo) {
        this.$open({
          name: "agreeDetail",
          path: `/agreedetail/${row.id}`,
          params: {
            id: row.id
          }
        });
        return
      }
      if(row.bizFlag === "1" && (row.status === "已撤回" || row.status === "被驳回")) {
          this.$message({
            type: 'warning',
            message: '该合同变更为业务类合同变更，请到业务系统编辑',
            duration: 1500
          })
          return
        }
      if (row.bizFlag === "0" && (row.auditStatus === "已撤回" || row.auditStatus === "被驳回")) {
        this.$open({
          name: "agreementEdit",
          path: `/agreementedit/${row.id}`,
          params: {
            id: row.id
          }
        });
        return
      }
      this.$open({
        name: "agreeDetail",
        path: `/agreedetail/${row.id}`,
        params: {
          id: row.id
        }
      });
    },
    // 跳转编辑页面
    clickExid() {
      console.log(this.contractInfo.type)
      if(this.isShowAmountChange) {
        this.$open({
          name: "amountModifyEdit",
          path: `/amountmodifyedit/${this.version}`,
          params: {
            id: this.version
          }
        })
      } else {
        this.$open({
          name: "changedEdit",
          path: `/changedEdit/${this.version || this.id}`,
          params: {
            id: this.version || this.id
          }
        })
      }


    },
    // 变更记录-跳转合同变更详情
    goChangeDetail(row) {
      if (this.currentUser !== row.operatorNo) {
        this.$open({
          name: "changeDetail",
          path: `/changedetail/${row.id}`,
          params: {
            id: row.id
          }
        });
        return;
      }
      if (row.auditStatus == "已撤回" || row.auditStatus == "被驳回" || row.auditStatus == "新建") {
        this.$open({
          name: "changedEdit",
          path: `/changededit/${row.id}`,
          params: {
            id: row.id
          }
        });
        return;
      }
      this.$open({
        name: "changeDetail",
        path: `/changedetail/${row.id}`,
        params: {
          id: row.id
        }
      });
    },
    // 下载附件
    download(row) {
      return this.$request
        .post("/file/fileDownload", { data: [{ "fileId": row.docLibId, "contractId": this.id }] })
        .then(res => {
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.style.height = 0;
          iframe.src = res.data;
          document.body.appendChild(iframe);
          setTimeout(()=>{
            iframe.remove();
          }, 5 * 60 * 1000);
        })
    },
    // 下载所有附件
    downloadAll() {
      this.fileData.forEach( item => {
        this.$request
        .post("/file/fileDownload", { data: [{ "fileId": item.docLibId, "contractId": this.id }] })
        .then(res => {
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";  // 防止影响页面
          iframe.style.height = 0;  // 防止影响页面
          iframe.src = res.data;
          document.body.appendChild(iframe);  // 这一行必须，iframe挂在到dom树上才会发请求
          setTimeout(()=>{
            iframe.remove();
          }, 5 * 60 * 1000);
        })
      })
    },
    /**
     * 发起付款对话框
     */
    // 处理金额输入框的校验
    limitInput(value, name) {
      return this.$limitRule(value,name,this.payformData)
    },
    // 打开窗口
    openPayDialog() {
      this.payFormVisible = true; // 显示表单窗口

      // 给主体选择赋值
      this.ourCompanyList = this.contractInfo.contractAllPayInfo.ourCompanyList || []
      this.payformData.ourCompanyId = this.ourCompanyList[0]?.id || ''
      this.supplierCompanyList = this.contractInfo.contractAllPayInfo.supplierCompanyList || []
      this.payformData.supplierCompanyId = this.supplierCompanyList[0]?.id || ''
      // 初始化表单内容
      this.payConInfo = this.contractInfo.contractAllPayInfo

      this.payConInfo.contractName = this.contractInfo.contractName

      this.payformData.contractId = this.id
      this.payformData.contractNo = this.contractInfo.contractNo
      this.payformData.currency = this.contractInfo.contractAllPayInfo.currency
    },
    // 发起付款
    initiatePay() {
      this.$refs["payForm"].validate(valid => {
        if (valid) {
          let params = this.payformData
          this.$request
            .post("/applyPayment/startApply", { params })
            .then(res => {
              if (res.code === "200" && res.data) {
                this.$message({
                  type: "success",
                  message: "提交成功，即将跳转付款信息页面",
                  duration: 1500,
                  onClose: () => {
                    this.payFormVisible = false;
                    this.payformData = {
                      moneyFlow: "支出",
                      payAmount: "",
                      ourCompanyId:"",
                      supplierCompanyId: "",
                      payNote: ""
                    };
                    window.open(res.data)
                  }
                });
              }
            });
        } else {
          this.$message({
            type: "error",
            message: "请填写必填项",
            duration: 1500
          })
        }
      });
    },

    // 发起补充协议
    goCreatAgree() {
      this.$open({
        name: "creatAgree",
        path: `/creatagree/${this.id}`,
        params: {
          id: this.id
        }
      });
    },

    // 发起合同变更
    goCreatChange() {
      this.$open({
        name: "creatChange",
        path: `/creatchange/${this.id}`,
        params: {
          id: this.id
        }
      });
    },

    // 获取主体信息
    getSubList() {
      return this.$request.post("/enterprise/queryEnterpriseAll").then(res => {
        if(res.code === "200") {
          let data = res.data
          this.ourCompanyList = data.T01
          this.supplierCompanyList = data.T02
        }
      });
    },
    checkNavList(val) {
      // 切换到合同信息 'contract'
      // 切换到合同信息 'agreement'
      // 切换到合同信息 'payList'
      // 切换到合同信息 'businessinfo'
      this.showInfo = val
    }
  },
  computed: {
    isShowExidBtn() {
      let status = this.hasStatus.status
      if (status === 'draft' || status === 'rejected' || status === 'withdrawn') {
        return true
      } else {
        return false
      }
    },
    //审批状态样式处理
    handelApproveStatus() {
      return function(statusCode) {
        let iconColor;
        switch (statusCode) {
          case "新建":
          case "已撤回":
          case "未审批":
          case "已删除(作废)":
          case "已驳回(撤回)":
            iconColor = "bg-dark-gray";
            break;
          case "审批中":
          case "协商中":
            iconColor = "bg-blue";
            break;
          case "已审批":
          case "已盖章":
          case "已归档":
            iconColor = "bg-dark-green";
            break;
          case "被驳回":
            iconColor = "bg-pink";
            break;
        }
        return iconColor;
      };
    },
    // 付款记录付款状态样式处理
    handelPayStatus() {
      return function(statusCode) {
        let iconColor;
        switch (statusCode) {
          case "未付款":
            iconColor = "bg-dark-gray";
            break;
          case "付款中":
          case "部分已付":
            iconColor = "bg-blue";
            break;
          case "付款完成":
            iconColor = "bg-dark-green";
            break;
        }
        return iconColor;
      };
    },
    // 是否展示信息修改原因字段
    isShowInfoChangeCause() {
      let _arr = this.versionOptions.filter(x => x.id === this.version)
      let _name =  _arr.length ? _arr[0].modifyName : false
      return _name ? _name.includes('-信息修改-V') : false
    },
    isShowContractVersion() {
      return this.versionOptions.length && (this.showInfo === 'contract' || this.showInfo === 'businessinfo')
    },
    // 是否展示框架类协议金额修改相关字段
    isShowAmountChange() {
      let _arr = this.versionOptions.filter(x => x.id === this.version)
      let _name =  _arr.length ? _arr[0].modifyName : false
      return _name ? _name.includes('-框架类协议金额修改-V') : false
    },
  },
  async created() {
    this.loading = this.$loading({
      fullscreen: true,
      background: "rgba(255, 255, 255, 0.8)",
      text: '正在加载数据...'
    })
    await this.queryEnterpriseAll().then(x => {
      this.getUrl().then(res => {this.getContractInfo(res)})
    })
    this.getCurrentUser()
  }
};
</script>

<style lang="less" scoped>
.page-box {
  position: relative;
  .no-data-wrapper {
    width: 200px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .title {
    font-size: 18px;
    color: #323232;
    font-weight: 600px;
    padding: 12px 18px;
    box-sizing: border-box;
    border-bottom: 1px solid #eaeaea;
  }
  .nav-wrapper {
    padding: 18px;
    padding-bottom: 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .subTitle {
      .button {
        display: inline-block;
        border: 1px solid #f0d8a8;
        min-width: 90px;
        height: 30px;
        line-height: 30px;
        box-sizing: border-box;
        text-align: center;
        padding: 0 12px;
        color: #997236;
        font-size: 12px;
        font-weight: 400;
        &:first-child {
          border-radius: 2px 0px 0px 2px;
        }
        &:last-child {
          border-radius: 0 2px 2px 0;
        }
        &:nth-child(n + 2) {
          margin-left: -1px;
        }
        &:hover {
          cursor: pointer;
        }
        &.active {
          background: #f0d8a8;
          color: #323232;
        }
      }
    }
    .select-box {
      .version-span {
        font-size: 12px;
        color: #323232;
        margin-right: 12px;
      }
      ::v-deep .el-select .el-input__inner {
        width: 240px;
        height: 30px;
        line-height: 30px;
        border-radius: 2px;
        font-size: 12px;
      }
      ::v-deep .el-select .el-input.is-focus .el-input__inner {
        border-color: #F0D8A8;
      }
      ::v-deep .el-select .el-input__inner:focus {
        border-color: #F0D8A8;
      }
      ::v-deep .el-input__icon {
        line-height: 30px;
      }
    }
  }
  .content-box.businessinfo{
    padding: 0;
  }
  .business-info-wrapper{
    width: 100%;
    height: calc(100vh - 170px);
    border: none;
  }
  .detail-card {
    .status {
      display: inline-block;
      padding: 0px 6px;
      box-sizing: border-box;
      line-height: 20px;
      border-radius: 2px;
      font-size: 10px;
      font-weight: 400;
      text-align: center;
      margin-left: 12px;
      i {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        // background: #73DBC0;
        margin-right: 2px;
        vertical-align: middle;
        margin-top: -2px;
      }
    }
    .card-header {
      line-height: 36px;

      .explain {
        padding: 0 78px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 12px;
        .item {
          .label {
            color: #999999;
            text-align: right;
          }

          .text {
            text-align: left;
          }
        }
      }
    }

    .card-content {
      .statusIcon {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 100%;
      }
      .statusName {
        display: inline-block;
        padding: 0 6px;
      }
      .el-row {
        margin-top: 12px;
      }
      .item {
        display: flex;
        flex-direction: row;
        font-size: 12px;
        line-height: 18px;

        .label {
          width: 145px;
          margin-right: 12px;
          color: #999;
          text-align: right;
        }
        .label-fill{
          line-height: 36px;
        }

        .text {
          color: #323232;
          text-align: left;
          flex: 1;

          .orderDownload {
            border-left: 1px solid #e5e5e5;
            height: 18px;
            margin-top: 9px;
            line-height: 18px;
            span {
              color: #997236;

              &:hover {
                cursor: pointer;
              }
            }
          }
          .light {
            color: #999999;
          }
        }
      }
      .linkName {
        color: #997236;
        margin-left: 12px;

        &:hover {
          cursor: pointer;
        }
      }
      .linkBtn {
        color: #997236;
        &:hover {
          cursor: pointer;
        }
      }
      .data-pic {
        margin-top: 15px;
      }
      .data-text {
        line-height: 0;
        margin: 10px;
        padding-bottom: 15px;
      }
    }
    .orderDownload {
      span {
        cursor: pointer;
      }
    }
  }
  .footer {
    .iconBox {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: 20px;
      &:hover {
        cursor: pointer;
      }
      .tips {
        box-sizing: border-box;
        width: 510px;
        padding: 12px;
        background: rgba(50, 50, 50, 0.95);
        border-radius: 4px;
        position: absolute;
        bottom: 44px;
        left: 6px;
        z-index: 99;

        &:hover {
          cursor: auto;
        }

        p {
          color: #ffffff;
          font-size: 12px;
          line-height: 18px;
        }
        p:last-child {
          margin-top: 6px;
        }
      }
    }
    .btnBox {
      width: 300px;
      height: 31px;
      position: absolute;
      right: 18px;
      top: 12px;
      button {
        float: right;
      }
    }
  }
  .payBox {
    min-width: 1184px;
    ::v-deep input::-webkit-input-placeholder {
      font-size: 12px;
    }
    input::-moz-input-placeholder {
      font-size: 12px;
    }
    input::-ms-input-placeholder {
      font-size: 12px;
    }
    ::v-deep .el-input__inner {
      font-size: 12px;
      border-radius: 2px;
    }
    .item {
      box-sizing: border-box;
      height: 66px;
      background-color: #f2f5fa;
      text-align: center;
      padding-top: 10px;
      color: #323232;
      border-radius: 4px;
      .text {
        font-weight: 600;
        font-size: 22px;
      }
      .label {
        font-size: 12px;
        font-weight: normal;
        color: #5b5b5b;
        line-height: 24px;
      }
    }
    .el-form {
      line-height: 24px;
    }
    & .el-row:first-child{
      .el-col{
        padding-right: 6px !important;
        padding-left: 6px !important;
      }
    }
    & .el-row:nth-child(2){
      height: 32px;
    }
    & .el-row:nth-child(3){
      height: 32px;
    }
    .el-row {
      margin-top: 18px;
    }
    .amount-input /deep/ .el-input__inner {
      text-align: left !important;
    }
  }
  .payBox /deep/ .el-dialog__header {
    border-bottom: 1px solid #eaeaea !important;
    padding: 15px 20px !important;
    height: 12px;
  }
  .payBox /deep/ .el-dialog__title{
    font-size: 12px !important;
    line-height: 12px;
  }
  .payBox /deep/ .el-dialog__headerbtn{
    top: 14px;
  }
  .payBox /deep/ .el-dialog__body {
    padding: 18px 20px;
  }
  .payBox /deep/ .el-dialog__footer {
    border-top: 1px solid #eaeaea;
    height: 54px;
    padding: 0;

    .dialog-footer {
      padding: 12px 18px;
    }
  }
  .btn {
    border-radius: 2px;
  }
}
.popover-text {
  font-size: 12px;
  color: #fff;
  li:last-child {
    margin-left: 39px;
  }
}
.el-select-dropdown__item.selected {
  color: #997236;
}
</style>
<style lang="less">
.popoverBackB {
  background: rgba(50, 50, 50, 0.95) !important;
}
.el-popper .popper__arrow::after {
  border-top-color: rgba(50, 50, 50, 0.95) !important;
}
.el-tooltip__popper{
  max-width: 100% !important;
  width: 300px;
}
</style>
<style lang="less">
.cross{
  color: red !important;
}
.el-popover{
  background: rgba(50,50,50,0.95);
  color: #fff;
  max-width: 95%;
}
.handle-down{
  .el-table .cell {
    padding-right: 0px !important;
  }
}
.text-align-right {
  text-align: right;
  color: #999999;
}
</style>
